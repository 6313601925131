// src/components/ReestablecerPage.js
import React from 'react';
import './ReestablecerPage.css'; // Importa los estilos CSS que acabamos de crear

function ReestablecerPage() {
    return (
        <div className="reestablecer-page">
            <div className="reestablecer-form-container">
                <h2>Reestablecer Contraseña</h2>
                <p>Introduce tu correo electrónico para recibir el enlace de reestablecimiento.</p>
                <form>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" placeholder="Ingresa tu email registrado" />
                    </div>
                    <button type="submit" className="reset-button">Enviar enlace de reestablecimiento</button>
                </form>
            </div>
        </div>
    );
}

export default ReestablecerPage;
