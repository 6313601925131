// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Cambiar a Link para navegación interna
import './Navbar.css';
import logoUPIITA from '../assets/logos/upiita-logo.png';
import logoIPN from '../assets/logos/ipn-logo.PNG';
import logoSTEM4ALL from '../assets/logos/stem4all-logo.png';

function Navbar() {
    const [showDropdown, setShowDropdown] = useState(false);

    const handleMouseEnter = () => setShowDropdown(true);
    const handleMouseLeave = () => setShowDropdown(false);

    return (
        <nav className="navbar">
            {/* Logos con enlaces */}
            <div className="navbar-logos">
                <Link to="/" target="_blank" rel="noopener noreferrer">
                    <img src={logoUPIITA} alt="Logo UPIITA" className="navbar-logo" />
                </Link>
                <Link to="/" target="_blank" rel="noopener noreferrer">
                    <img src={logoIPN} alt="Logo IPN" className="navbar-logo" />
                </Link>
                <Link to="/" target="_blank" rel="noopener noreferrer">
                    <img src={logoSTEM4ALL} alt="Logo STEM4ALL" className="navbar-logo" />
                </Link>
            </div>

            <ul className="navbar-links">
                <li className="navbar-item dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <span className="dropdown-title">Cursos</span>
                    {showDropdown && (
                        <div className="dropdown-menu">
                            {/* Dropdown content */}
                        </div>
                    )}
                </li>
                <li className="navbar-item">
                    <Link to="/about#collaboration">Instituciones</Link>
                </li>
                <li className="navbar-item">
                    <Link to="/about#mission-vision">Misión y Visión</Link>
                </li>
                <li className="navbar-item">
                    <Link to="/about">¿Quiénes Somos?</Link>
                </li>
                <li className="navbar-item">
                    <Link to="/contact">Contacto</Link>
                </li>
            </ul>
            <Link to="/login">
                <button className="navbar-button">Identifícate</button>
            </Link>
        </nav>
    );
}

export default Navbar;
