// src/components/RegistroPage.js
import React from 'react';
import './RegistroPage.css';

function RegistroPage() {
    return (
        <div className="registro-page">
            <h2>Crear una cuenta</h2>
            <form>
                <div className="form-group">
                    <label>Nombre</label>
                    <input type="text" placeholder="Ingresa tu nombre completo" />
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input type="email" placeholder="Ingresa tu email" />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input type="password" placeholder="Crea una contraseña" />
                </div>
                <button type="submit" className="register-button">Registrarse</button>
            </form>
        </div>
    );
}

export default RegistroPage;
